import { isNonNull } from "helpers/isNonNull";
import React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { GUIDES_LINKS } from "../navigation/links";
import { Tile } from "../tile/Tile";

interface SectionProps {
  title: string;
  subtitle: string;

  /**
   * Array of data to generate tiles from, or actual elements
   */
  tiles: Array<
    | React.ReactElement
    | {
        /**
         * The link, as an absolute link from docs like `/docs/xyz`
         */
        to: string;
        title: string;
        subtitle: string;
      }
  >;

  /**
   * Margin for the divider up top, defaults to "32px 0 56px" if missing
   */
  margin?: string;

  /**
   * Whether to show the divider
   */
  showDivider?: boolean;

  id?: string;
}

export const SectionTitle = styled.h4`
  && {
    font-size: 20px;
    line-height: 32px;
    color: var(--gray90);
  }
`;

export const SectionSubtitle = styled.h6`
  && {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: var(--gray90);
    margin: 0 0 32px;
  }
`;

const DocsRow = styled(Row)`
  && {
    align-items: stretch;
  }
`;

const DocsCol = styled(Col)`
  && {
    margin: 0px 0;
  }
`;

/**
 * Takes an array of elements or tiles and maps it to a column. Meant for a two
 * column layout.
 */
const elementsFromTiles = (tiles: SectionProps["tiles"], column: 0 | 1) =>
  tiles
    .map((tile, index) => (index % 2 === column ? tile : null))
    .filter(isNonNull)
    .map((tile: any) => {
      if (React.isValidElement(tile)) {
        return tile;
      }
      return <Tile {...tile} key={tile.to} />;
    });

/**
 * Creates a single section with a divider up top
 */
const GuidesSection = ({
  title,
  subtitle,
  tiles,
  margin = "32px 0 56px",
  showDivider = true,
  id,
}: SectionProps) => (
  <>
    {showDivider && <hr style={{ margin }} id={id} />}
    <SectionTitle id={showDivider ? undefined : id}>{title}</SectionTitle>
    <SectionSubtitle>{subtitle}</SectionSubtitle>
    <DocsRow>
      <DocsCol xs={12} md={6}>
        {elementsFromTiles(tiles, 0)}
      </DocsCol>
      <DocsCol xs={12} md={6}>
        {elementsFromTiles(tiles, 1)}
      </DocsCol>
    </DocsRow>
  </>
);

export default GuidesSection;

export const UnifiedAPISection = ({ showDivider = true }: Pick<SectionProps, "showDivider">) => (
  <GuidesSection
    title="Unified API Basics"
    subtitle="Fundamentals of interacting with the Merge Unified API."
    tiles={[
      {
        to: "/basics/authentication",
        title: "Authentication",
        subtitle: "Authenticate requests to Merge's API",
      },
      {
        to: "/basics/pagination",
        title: "Pagination",
        subtitle: "Paginate bulk data from Merge's API",
      },
      {
        to: "/basics/syncing-data",
        title: "Syncing Data",
        subtitle: "Efficiently pull data from Merge into your app",
      },
      {
        to: "/basics/sync-frequency",
        title: "Sync Frequency",
        subtitle:
          "Learn about the different sync frequencies for data from third parties to Merge. ",
      },
      {
        to: "/basics/rate-limits",
        title: "Rate Limits",
        subtitle: "Learn about rate limits for the Merge API",
      },
      {
        to: "/basics/webhooks/overview/",
        title: "Webhooks",
        subtitle: "Use Merge's webhooks to keep your application up to date in real-time",
      },
      {
        to: "/basics/versioning",
        title: "Versioning",
        subtitle: "Learn about versioning for the Merge API",
      },
      {
        to: "/basics/async-operations",
        title: "Async Operations",
        subtitle: "Learn about asynchronous operations for the Merge API",
      },
      {
        to: "/basics/idempotency",
        title: "Idempotency",
        subtitle: "Learn about idempotency for the Merge API",
      },
    ]}
    showDivider={showDivider}
  />
);

export const MergeLinkSection = ({ showDivider = true }: Pick<SectionProps, "showDivider">) => (
  <GuidesSection
    title="Merge Link"
    subtitle="Connect your users to third-party platforms."
    tiles={[
      {
        to: "/get-started/link/",
        title: "Embedded Link",
        subtitle: "Add Link to your product",
      },
      GUIDES_LINKS.MERGE_MAGIC_LINK,
      {
        to: "/guides/merge-link/single-integration/",
        title: "Single Integration",
        subtitle: "Direct your user to setup the configuration of a single integration",
      },
    ]}
    showDivider={showDivider}
  />
);

export const WritingDataSection = ({ showDivider = true }: Pick<SectionProps, "showDivider">) => (
  <GuidesSection
    title="Writing Data"
    subtitle="Write data to third-party platforms through the Merge Unified API."
    tiles={[GUIDES_LINKS.WRITES_OVERVIEW, GUIDES_LINKS.TROUBLESHOOTING_WRITES]}
    showDivider={showDivider}
  />
);

export const PlatformAndAccountMetadataSection = ({
  showDivider = true,
}: Pick<SectionProps, "showDivider">) => (
  <GuidesSection
    title="Platform and Account Metadata"
    subtitle="Fetch integration and account metadata."
    tiles={[
      {
        to: "/basics/integration-metadata",
        title: "Integration Metadata",
        subtitle: "Programmatically fetch information about the integrations Merge offers",
      },
      {
        to: "/basics/linked-accounts",
        title: "Linked Accounts",
        subtitle: "Programmatically fetch available operations for a Linked Account",
      },
    ]}
    showDivider={showDivider}
  />
);

export const SupplementalDataSection = ({
  showDivider = true,
}: Pick<SectionProps, "showDivider">) => (
  <div className="mb-9">
    <GuidesSection
      title="Supplemental Data"
      subtitle="Get data from third-party platforms in its original format"
      tiles={[
        {
          to: "/supplemental-data/remote-data",
          title: "Remote Data",
          subtitle: "Get the latest integration data in the original format Merge received it in",
        },
        {
          to: "/supplemental-data/passthrough/overview",
          title: "Passthrough Request",
          subtitle: "Make a request to the integration API using linked account credentials",
        },
        {
          to: "/supplemental-data/remote-fields/overview",
          title: "Remote Field Classes",
          subtitle:
            "Read and write non-mapped data in a standardized format via Merge's Unified API",
        },
        {
          to: "/supplemental-data/field-mappings/overview",
          title: "Field Mapping",
          subtitle: "Map non-mapped data from your users' platforms to Merge Common Models",
        },
        {
          to: "/supplemental-data/custom-objects",
          title: "Custom Objects",
          subtitle: "Use Merge to interact with custom objects on your users' CRM platforms.",
        },
      ]}
      showDivider={showDivider}
    />
  </div>
);
