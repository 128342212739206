import React, { CSSProperties } from "react";
import { ChevronRight } from "react-feather";
import styled from "styled-components";
import { palette } from "styles/theme";
import glowCSS from "./glow";
import background from "./background";

interface ButtonInterface {
  showChevron: boolean;
  backgroundColor: string;
  borderRadius?: number;
  white?: boolean;
  glow?: boolean;
}

const Button = styled.button<ButtonInterface>`
  && {
    border-radius: ${({ borderRadius }) => borderRadius ?? 6}px;
    position: relative;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    transition: all 0.1s ease-out;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    white-space: nowrap;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;

    ${({ showChevron }) => (showChevron ? `padding: 8px 12px 8px 16px;` : `padding: 8px 16px;`)}

    ${background}
    
    svg {
      width: 12px;
      height: 12px;
      transform: translate(1px, 1px);
      transition: transform 0.1s ease-out;
    }

    &:hover,
    &:focus {
      outline: none;
      svg {
        transform: translate(2.5px, 1px);
      }
    }

    &:disabled {
      opacity: 0.35;
      pointer-events: none;
    }
  }
`;

const Text = styled.span`
  && {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    white-space: nowrap;
    margin: 0;
    padding: 0;
  }
`;

interface Props {
  color?: string;
  className?: string;
  style?: CSSProperties;
  gtagId?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: JSX.Element | JSX.Element[] | string;
  text?: string;
  showChevron?: boolean;
  borderRadius?: number;
  white?: boolean;
  glow?: boolean;
}

const GlowButton = ({
  color = palette.blue,
  className,
  style,
  gtagId = "",
  onClick,
  text = "Get started",
  showChevron = false,
  borderRadius,
  children,
  white = false,
  glow = false,
}: Props) => (
  <Button
    className={className}
    style={style}
    onClick={onClick}
    showChevron={showChevron}
    backgroundColor={color}
    id={gtagId}
    borderRadius={borderRadius}
    white={white}
    glow={glow}
  >
    {children ?? <Text>{text}</Text>}
    {showChevron && <ChevronRight />}
  </Button>
);

export default GlowButton;
