import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { ChevronRight } from "react-feather";

const StyledLink = styled(Link)`
  && {
    border: none;
    box-shadow: none;
    color: var(--gray60);
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    display: flex;
    align-items: center;
    transition: color 0.1s ease-out;

    svg {
      margin: 1px 0 0 2px;
      transform: translateX(0);
      transition:
        transform 0.1s ease-out,
        color 0.1s ease-out;
      color: inherit;
    }

    &:hover,
    &:focus {
      color: var(--gray80);

      svg {
        transform: translateX(2px);
      }
    }
  }
`;

interface Props {
  to: string;
}

const ViewMore = ({ to }: Props) => (
  <StyledLink to={to}>
    View more
    <ChevronRight size="16px" />
  </StyledLink>
);

export default ViewMore;
