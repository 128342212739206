import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { palette } from "styles/theme";
import { StaticImage } from "gatsby-plugin-image";
import { Col, Row } from "react-bootstrap";
import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import { StaticPageContainer } from "components/docs/shared-components/PageContainers";
import GuidesPreviewSection from "components/docs/home/GuidesPreviewSection";
import APIReferencePreviewSection from "components/docs/home/APIReferencePreviewSection";
import Button from "components/generic/Button/Button";
import LINKS from "components/docs/navigation/links";

const Pretitle = styled.h6`
  &&& {
    width: fit-content;
    color: var(--blue40);
    margin: 80px 0 20px 1px;
    font-size: 14px;
    line-height: 24px;

    @media (min-width: 768px) {
      margin-top: 0;
    }
  }
`;

const Title = styled.h2`
  && {
    font-size: 32px;
    line-height: 40px;
    color: var(--gray90);
    margin: 0 0 24px -2px;

    @media (min-width: 768px) {
      font-size: 48px;
      line-height: 56px;
    }
  }
`;

const Subtitle = styled.h6`
  && {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: var(--gray90);
    margin: 0 0 40px;
    max-width: 360px;

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 26px;
    }
  }
`;

const ButtonText = styled.p`
  && {
    font-size: 16px;
    line-height: 26px;
    margin: 0 4px 0 0;
  }
`;

const ImageContainer = styled(Col)`
  transform: translate(0, 20px) scale(1.2);

  @media (min-width: 768px) {
    transform: translate(0, 20px) scale(1);
  }
`;

export default function Home() {
  return (
    <StaticPageContainer lessPadding>
      <DocsHelmet
        title="Merge Docs"
        description="Learn how to add Merge to your product and interact with the Unified API"
      />
      <Row>
        <Col xs={12} md={5} className="d-flex flex-column justify-content-center">
          <Pretitle>Merge Docs</Pretitle>
          <Title>Get Started</Title>
          <Subtitle>
            Add Link and connect to our Unified API to start offering integrations in your product
          </Subtitle>
          <Link to={LINKS.GET_STARTED_INTRODUCTION.linkTo}>
            <Button
              color={palette.black}
              glow
              showChevron
              style={{ padding: "9px 20px 9px 24px" }}
              borderRadius={8}
            >
              <ButtonText>Get started</ButtonText>
            </Button>
          </Link>
        </Col>
        <Col xs={12} md={7}>
          <ImageContainer>
            <StaticImage
              src="../assets/images/illustrations/link-code-isometric.png"
              alt=""
              placeholder="none"
              layout="constrained"
              quality={100}
              height={640}
            />
          </ImageContainer>
        </Col>
      </Row>
      <hr style={{ margin: "32px 0" }} />
      <APIReferencePreviewSection />
      <hr style={{ margin: "32px 0" }} />
      <GuidesPreviewSection />
    </StaticPageContainer>
  );
}
