import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import {
  SectionTitle as GuidesSectionTitle,
  SectionSubtitle as GuidesSectionSubtitle,
  UnifiedAPISection,
  WritingDataSection,
  PlatformAndAccountMetadataSection,
  SupplementalDataSection,
  MergeLinkSection,
} from "components/docs/sections/GuidesSection";
import ViewMore from "../navigation/ViewMore";

const SectionTitle = styled.h2`
  && {
    font-size: 32px;
    line-height: 44px;
    font-weight: 600;
    color: var(--gray90);
    margin: 56px 0 16px;
  }
`;

const SectionSubtitle = styled.h5`
  && {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    color: var(--gray90);
    margin: 0 0 24px;
  }
`;

const SectionHolder = styled(Col)`
  ${GuidesSectionTitle} {
    font-size: 20px;
    line-height: 32px;
    color: var(--gray90);
    margin: 40px 0 8px;
  }

  ${GuidesSectionSubtitle} {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: var(--gray90);
    margin: 0 0 32px;
  }
`;

export default function GuidesPreviewSection() {
  return (
    <div>
      <SectionTitle>Guides</SectionTitle>
      <SectionSubtitle>
        In-depth guides to integrate with Merge and third-party platforms.
      </SectionSubtitle>
      <SectionHolder>
        <UnifiedAPISection showDivider={false} />
        <MergeLinkSection showDivider={false} />
        <WritingDataSection showDivider={false} />
        <PlatformAndAccountMetadataSection showDivider={false} />
        <SupplementalDataSection showDivider={false} />
        <ViewMore to="/guides#testing" />
      </SectionHolder>
    </div>
  );
}
