import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, IGatsbyImageData, StaticImage } from "gatsby-plugin-image";
import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { APICategory, Badge } from "@merge-api/merge-javascript-shared";
import useCategories from "../hooks/useCategories";
import { isApiCategoryEnum, titleifyCategory } from "../utils/services";
import { CategoryPublicationStatus } from "../../../types/types";
import DeprecatedH2 from "components/deprecated/DeprecatedH2";
import DeprecatedH5 from "components/deprecated/DeprecatedH5";

interface ImageQueryType {
  allFile: {
    edges: Array<{
      node: {
        name: string;
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData;
        };
      };
    }>;
  };
}

const SectionTitle = styled(DeprecatedH2)`
  && {
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    color: var(--gray90);
    margin: 56px 0 16px;
  }
`;

const SectionSubtitle = styled.h5`
  && {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    color: var(--gray90);
    margin: 0 0 48px;
  }
`;

const DocsRow = styled(Row)`
  && {
    align-items: stretch;
  }
`;

const DocsCol = styled(Col)`
  && {
    margin: 8px 0;
  }
`;

const TileTitle = styled(DeprecatedH5)`
  && {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: #121314;
    margin: 0 0 2px 0;
  }
`;

const TileSmallTitle = styled(TileTitle)`
  && {
    margin: 0 0 2px 0;
  }
`;

const TileDescription = styled.p`
  && {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #727581;
    margin: 0;
  }
`;

const RowLabel = styled(DeprecatedH5)`
  && {
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    color: #727581;
    padding: 0;
    margin: 0 0 12px;
  }
`;

const TileSmallCol = styled(DocsCol)`
  && {
  }
`;

const TileSmall = styled.div`
  display: flex;
  > * + * {
    margin-left: 12px;
  }
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  min-height: 104px;
  padding: 16px 20px;
  margin: 0;
  background: #ffffff;
  box-shadow: 0px 3px 16px -6px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.1s ease-out;
  border-radius: 8px;
  cursor: pointer;

  &:hover,
  &:focus {
    box-shadow:
      0px 0px 0px 5px rgba(223, 225, 228, 0.25),
      0px 6px 30px -2px rgba(0, 0, 0, 0.12);
  }
`;

const TileSmallImageContainer = styled.div`
  padding: 0px;
  margin-left: -8px;
`;

const TileSmallTextContainer = styled.div``;

export default function Home() {
  const categories = useCategories();
  const imagesQuery = useStaticQuery<ImageQueryType>(graphql`
    {
      allFile(filter: { extension: { eq: "png" }, relativeDirectory: { eq: "images/category" } }) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 60, layout: FIXED, placeholder: NONE)
            }
          }
        }
      }
    }
  `);
  const categoryImageMap = useMemo<Partial<Record<APICategory, IGatsbyImageData>>>(
    () =>
      imagesQuery.allFile.edges.reduce(
        (accum, { node }) =>
          isApiCategoryEnum(node.name)
            ? { ...accum, [node.name]: node.childImageSharp.gatsbyImageData }
            : accum,
        {},
      ),
    [imagesQuery],
  );

  return (
    <>
      <SectionTitle>API Reference</SectionTitle>
      <SectionSubtitle>
        Get to know the Merge Unified API by exploring our API reference.
      </SectionSubtitle>
      <RowLabel>Fundamentals</RowLabel>
      <DocsRow style={{ marginBottom: 40 }}>
        <DocsCol xs={12} md={4}>
          <Link to="/basics/authentication">
            <TileSmall>
              <TileSmallImageContainer>
                <StaticImage
                  src="../../../assets/images/category/basics.png"
                  alt="Basics"
                  layout="fixed"
                  width={60}
                  placeholder="none"
                />
              </TileSmallImageContainer>
              <TileSmallTextContainer>
                <TileSmallTitle>Merge API Basics</TileSmallTitle>
                <TileDescription>
                  Nuts and bolts like authentication, pagination, and webhooks
                </TileDescription>
              </TileSmallTextContainer>
            </TileSmall>
          </Link>
        </DocsCol>
      </DocsRow>
      <RowLabel>Platform Categories</RowLabel>
      <DocsRow style={{ marginBottom: 64 }}>
        {Object.values(APICategory).map((category) => {
          const { categoryPreview, publicationStatus } = categories[category];

          return (
            <TileSmallCol xs={12} md={4}>
              <Link to={`/${category}/overview`}>
                <TileSmall>
                  <TileSmallImageContainer>
                    <GatsbyImage image={categoryImageMap[category]!} alt={category} />
                    {publicationStatus === CategoryPublicationStatus.beta && (
                      <Badge className="ml-2" size="lg" color="blue">
                        Beta
                      </Badge>
                    )}
                  </TileSmallImageContainer>
                  <TileSmallTextContainer>
                    <TileSmallTitle>{titleifyCategory(category)}</TileSmallTitle>
                    <TileDescription>
                      Integrate with multiple {categoryPreview} platforms using Merge&apos;s Unified
                      API
                    </TileDescription>
                  </TileSmallTextContainer>
                </TileSmall>
              </Link>
            </TileSmallCol>
          );
        })}
      </DocsRow>
    </>
  );
}
